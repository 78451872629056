import { format, parseISO, isMatch, parse } from 'date-fns';
import { enAU } from 'date-fns/locale';

export const dateMixin = {
  methods: {
    /**
     * Format date and time for view
     */
    formatDate(date) {
      if (!date) return null;
      if (!isMatch(date, 'yyyy-MM-dd')) return null;
      return format(parseISO(date), 'dd/MM/yyyy');
    },
    /**
     * Format date and time
     *
     * @param {string} date
     * @returns
     */
    formatDateAndTime(date) {
      if (!date) return null;
      return format(parseISO(date), 'dd/MM/yyyy HH:mm');
    },
    /**
     * Parse date and time back to MySQL friendly format
     */
    parseDate(date) {
      if (!date) return null;
      if (!isMatch(date, 'dd/MM/yyyy')) return null;
      return format(parse(date, 'dd/MM/yyyy', new Date(), { locale: enAU }), 'yyyy-MM-dd');
    },
    /**
     * Format time
     */
    formatTime(date) {
      if (!date) return '';
      const time = date.split(':');
      return time[0] + ':' + time[1];
    }
  }
};
