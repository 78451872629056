import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('ValidationObserver',{ref:"objects",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c(VForm,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"From Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"From Date","error-messages":errors},model:{value:(_vm.from_date),callback:function ($$v) {_vm.from_date=$$v},expression:"from_date"}})}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"To Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('DateInput',{attrs:{"label":"To Date","error-messages":errors},model:{value:(_vm.to_date),callback:function ($$v) {_vm.to_date=$$v},expression:"to_date"}})}}],null,true)})],1),(_vm.reportClient)?_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('AutoComplete',{attrs:{"label":"Client","error-messages":errors,"search-service":"organisation"},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})}}],null,true)})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Type","rules":"required"}},[_c(VSelect,{attrs:{"items":_vm.allowedFileTypes,"menu-props":{ maxHeight: '400' },"label":"Type","auto-select-first":"","flat":""},model:{value:(_vm.defaultFileType),callback:function ($$v) {_vm.defaultFileType=$$v},expression:"defaultFileType"}})],1)],1)],1),_c(VRow,[_c(VCol,{staticClass:"justify-end",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"primary",attrs:{"disabled":invalid},on:{"click":function($event){return _vm.okAction()}}},[_vm._v("Export")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }