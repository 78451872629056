import * as API from '@/services/API';

export default {
  /**
   * Get a report
   *
   * @param {Object} options
   * @returns string
   */
  async getReport(options) {
    const query = API.apiHelper.getQueryStringFromObject(options);
    return API.apiClient.get(`/export${query}`, { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', options.filename);
      document.body.appendChild(link);
      link.click();
    });
  }
};
