<template>
  <v-container>
    <ValidationObserver ref="objects" v-slot="{ invalid }">
      <v-form>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="From Date" rules="required">
              <DateInput v-model="from_date" label="From Date" :error-messages="errors" slot-scope="{ errors }" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="To Date" rules="required">
              <DateInput v-model="to_date" label="To Date" :error-messages="errors" slot-scope="{ errors }" />
            </ValidationProvider>
          </v-col>
          <v-col v-if="reportClient" cols="12">
            <ValidationProvider name="Client">
              <AutoComplete
                v-model="client"
                label="Client"
                :error-messages="errors"
                slot-scope="{ errors }"
                search-service="organisation"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="defaultFileType"
                :items="allowedFileTypes"
                :menu-props="{ maxHeight: '400' }"
                label="Type"
                auto-select-first
                flat
              ></v-select>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="justify-end">
            <v-btn class="primary" @click="okAction()" :disabled="invalid">Export</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </v-container>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import store from '@/store/index';
import ReportService from '@/services/ReportService';
import DateInput from '@/views/DateInput';
import { getError } from '@/utils/helpers';
import AutoComplete from '@/components/AutoComplete';

extend('required', required);

export default {
  name: 'ByDateExportDialog',
  components: {
    AutoComplete,
    ValidationProvider,
    ValidationObserver,
    DateInput
  },
  props: {
    /**
     * The prefix to use for the report file name
     */
    reportFilenamePrefix: {
      type: String,
      default: 'Certificate of Attendence'
    },
    /**
     * Name used for report in Laravel REST API
     */
    reportName: {
      type: String,
      default: 'certificate-of-attendence'
    },
    /**
     * Report client show or hide
     */
    reportClient: {
      type: Boolean,
      default: false
    },
    /**
     * File types allowed for export
     */
    allowedFileTypes: {
      type: Array,
      default: () => ['pdf']
    },
    /**
     * The default file type to select for the export
     */
    defaultFileType: {
      type: String,
      default: 'pdf'
    }
  },
  methods: {
    /**
     * Create the file name for the export
     */
    filename() {
      let filename = this.reportFilenamePrefix + ' ' + this.from_date + ' ' + this.to_date;
      return filename.replace(/[^a-z0-9]/gi, '-') + '.' + this.defaultFileType;
    },
    /**
     * Save method for the form
     */
    async okAction() {
      ReportService.getReport({
        report: this.reportName,
        filename: this.filename(),
        from_date: this.from_date,
        to_date: this.to_date,
        client_id: this.client?.id ?? -1
      }).catch(error => {
        store.dispatch('ui/snackbarError', getError(error));
      });
    }
  },
  data: () => ({
    from_date: null,
    to_date: null,
    client: null
  })
};
</script>
