<template>
  <v-autocomplete
    v-model="autoCompleteValue"
    :search-input.sync="search"
    :loading="loading"
    :items="items"
    :label="label"
    item-text="title"
    item-value="id"
    no-data-text="Not found"
    :disabled="disabled"
    :error-messages="errorMessages"
    clearable
    flat
    hide-selected
    hide-no-data
    return-object
    :menu-props="{ maxHeight: '400' }"
    @change="changeHandler"
  >
    <template v-slot:item="data">
      <template>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.title"></v-list-item-title>
          <v-list-item-subtitle v-if="data.item.subtitle" v-html="data.item.subtitle"></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
    <template v-slot:append-item>
      <div v-intersect="endIntersect" />
    </template>
  </v-autocomplete>
</template>
<script>
import { getError } from '@/utils/helpers';
import ItineraryItemTemplateService from '@/services/ItineraryItemTemplateService';
import OrganisationService from '@/services/OrganisationService';
import PersonService from '@/services/PersonService';
import StaffMemberService from '@/services/StaffMemberService';

export default {
  name: 'AutoComplete',
  props: {
    value: Object,
    disabled: Boolean,
    label: String,
    errorMessages: Array,
    searchService: String,
    sortBy: {
      default: 'title',
      type: String
    },
    sortOrder: {
      default: 'asc',
      type: String
    }
  },
  data() {
    return {
      autoCompleteValue: null,
      loading: false,
      items: [],
      search: null,
      timerID: null,
      searchTerm: null,
      error: null,
      nextPage: null
    };
  },
  watch: {
    search(value) {
      if (!value) return false;
      if (this.autoCompleteValue && this.autoCompleteValue.title === value) return false;
      this.searchTerm = value;
      this.fetchEntriesDebounced();
    },
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.loading = true;
        this.items = [];
        this.autoCompleteValue = null;
        if (newValue && newValue != oldValue) {
          this.items.push(newValue);
          this.autoCompleteValue = newValue;
        }
        this.loading = false;
      }
    }
  },
  methods: {
    /**
     * Pagniate autocomplete
     */
    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        if (this.nextPage != null) {
          this.loading = true;
          this.service()
            .paginate(this.nextPage)
            .then(response => {
              this.nextPage = response.data.links.next;
              this.items = [...this.items, ...response.data.data];
              this.loading = false;
            });
        }
      }
    },
    changeHandler() {
      this.$emit('input', this.autoCompleteValue);
    },
    fetchEntriesDebounced() {
      clearTimeout(this.timerID);
      this.timerID = setTimeout(() => {
        this.searchKeyword(this.searchTerm);
      }, 200);
    },
    service() {
      switch (this.searchService) {
        case 'itineraryItemTemplate':
          return ItineraryItemTemplateService;
        case 'organisation':
          return OrganisationService;
        case 'person':
          return PersonService;
        case 'teacher':
          return PersonService;
        case 'staff':
          return StaffMemberService;
        default:
          console.error('Service not supported: ' + this.searchService);
      }
      return null;
    },
    searchKeyword(keyword) {
      this.loading = true;
      this.service()
        .search({ keyword: keyword, subtitle: 1, sort_by: this.sortBy, sort_desc: this.sortOrder, per_page: 30 })
        .then(response => {
          this.items = response.data.data;
          this.loading = false;
          this.nextPage = response.data.links.next;
        })
        .catch(error => {
          this.loading = false;
          this.error = getError(error);
        });
    }
  }
};
</script>
