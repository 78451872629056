import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"label":_vm.label,"hint":"DD/MM/YYYY format","maxlength":"10","persistent-hint":"","error-messages":_vm.errorMessages,"clearable":""},on:{"keypress":_vm.keyPressHandler,"change":function($event){return _vm.inputHandler($event)}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"no-title":""},on:{"input":function($event){(_vm.menu = false), _vm.selectHandler($event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }