<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :disabled="disabled"
        :label="label"
        hint="DD/MM/YYYY format"
        maxlength="10"
        persistent-hint
        :error-messages="errorMessages"
        v-bind="attrs"
        v-on="on"
        @keypress="keyPressHandler"
        @change="inputHandler($event)"
        clearable
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title @input="(menu = false), selectHandler($event)"></v-date-picker>
  </v-menu>
</template>

<script>
import { dateMixin } from '@/mixins/dateMixin';

export default {
  name: 'DateInput',
  props: ['value', 'label', 'disabled', 'error-messages'],
  data: () => ({
    date: null,
    dateFormatted: '',
    menu: false
  }),
  mixins: [dateMixin],
  methods: {
    keyPressHandler(e) {
      const char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[0-9*/+]+$/.test(char)) return true; // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    selectHandler() {
      this.$emit('input', this.date);
    },
    inputHandler() {
      this.date = this.parseDate(this.dateFormatted);
      this.$emit('input', this.date);
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        this.date = null;
        this.dateFormatted = '';
        if (newValue && newValue != oldValue) {
          this.date = newValue;
          this.dateFormatted = this.formatDate(this.date);
        }
      }
    }
  }
};
</script>
